@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=2514bdef-d96a-4f4d-9c1e-482f03c1554f");
@font-face{
  font-family:"Univers LT W01_55 Roman1475956";
  src:url("Fonts/5c8d59c2-9c85-4a22-88e3-bf3fba01dcd8.woff2") format("woff2"),url("Fonts/fc51d03b-0d7d-45df-9415-3c6270c80749.woff") format("woff");
}

@mixin univers() {
  font-family:"Univers LT W01_55 Roman1475956";
}

@function calcRem( $size ) {
  @return  ( $size / 16px ) * 1rem;
}

@function calcGrid( $size ) {
  $col_num: 12;
  @return ( ( 100% / $col_num * $size ) );
}

@mixin letterSpacing( $fontSize, $value ) {
  letter-spacing: calc( #{$fontSize} * 0.001 * #{$value} );
}

@mixin defaultTransition() {
  transition: all .2s ease-out;
}

@mixin font($fontSize: 1rem, $weight: 400, $style: normal, $kerning: 0, $lineheight: 1.75 ) {
  font-size: $fontSize;
  font-weight: $weight;
  font-style: $style;
  line-height: $lineheight;
  @include letterSpacing( $fontSize, $kerning );
}
